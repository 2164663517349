import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})

export class LoaderService {
  private loadingSubject = new BehaviorSubject<boolean>(false);
  public loading$ = this.loadingSubject.asObservable();

  constructor() {}

  show() {
    setTimeout(() => {
      this.loadingSubject.next(true);
    }, 0);
  }
  
  hide() {
    setTimeout(() => {
      this.loadingSubject.next(false);
    }, 0);
  }
}